<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #555;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Notificações</v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                    <v-spacer/>
                    <v-btn color="primary" fab small @click="openCadastro">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 180"
                            @click:row="editar"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :search="search"
                        >
                            <template v-slot:item.created_at="{ item }">
                                {{ formatDate(item.created_at) }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                <Notificacao
                    :visible="visible"
                    :formData="form"
                    @onCadastro="onCadastro"
                    @close="close"
                />
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import Notificacao from './Notificacao';

export default {
    name: 'Notificacoes',

    components: {
        BaseContainer,
        Notificacao,
    },

    data: () => ({
        search: '',
        visible: false,
        loading: false,
        dados: [],
        form: {},
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'Titulo', value: 'title' },
            { text: 'Status', value: 'status' },
            { text: 'Visualizações', value: 'visualizacoes' },
            { text: 'Data/Hora', value: 'created_at' },
        ],
    }),

    mounted() {
        this.consultar();
    },

    methods: {
        consultar() {
            if (!this.checkPermission('zapercontrol_usuarios_notificacoes_consultar')) {
                alert('Usuário sem permissão');
                return;
            }

            this.loading = true;
            this.dados = [];

            this.$http.get('notificacoes').then(resp => {
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                alert('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },

        close() {
            this.visible = false;
            this.form = {};
        },

        openCadastro() {
            if (!this.checkPermission('zapercontrol_notificacoes_adicionar')) {
                alert('Usuário sem permissão');
                return;
            }

            this.form = {};
            this.visible = true;
        },

        onCadastro() {
            this.close();
            this.consultar();
        },

        editar(row) {
            if (!this.checkPermission('zapercontrol_notificacoes_atualizar')) {
                alert('Usuário sem permissão');
                return;
            }

            this.form = row;
            this.visible = true;
        },
    },
}
</script>
