<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card light>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">
                    {{ form.id ? 'Edição' : 'Cadastro' }} de Notificações
                </span>
                <v-spacer/>
            </v-card-title>
            <v-card-text align="center" justify="center">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Icone"
                            v-model="form.icon"
                            dense
                            outlined
                            hide-details
                        />
                        <span @click="openLink('https://pictogrammers.com/library/mdi/')">
                            https://pictogrammers.com/library/mdi/
                        </span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Titulo"
                            v-model="form.title"
                            dense
                            outlined
                            hide-details
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            label="Texto"
                            v-model="form.text"
                            rows="4"
                            clear-icon="mdi-backspace-outline"
                            clearable
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-switch
                            dense
                            inset
                            hide-details
                            v-model="form.onlyAdmin"
                            label="Notificar somente Administradores"
                            outlined
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer/>
                <v-btn color="success" @click="salvar" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'Notificacao',

    props: {
        visible: {
            type: Boolean,
            default: false
        },
        formData: {
            type: Object,
            default: Object
        },
    },

    data: () => ({
        form: {},
        loading: false,
    }),

    mounted() {
        this.form = this.formData;
    },

    watch: {
        formData(value) {
            this.form = value ? JSON.parse(JSON.stringify(value)) : {};
        }
    },

    methods: {
        salvar() {
            const id = this.form.id;

            if (id) {
                if (!this.checkPermission('zapercontrol_notificacoes_atualizar')) {
                    alert('Usuário sem permissão');
                    return;
                }

                this.loading = true;
                this.$http.put(`notificacoes/${id}`, this.form).then(resp => {
                    if (resp.data.type === 'warning') {
                        alert(resp.data.msg);
                        return;
                    }
                    this.$emit('onCadastro');
                    // this.notify('Atualizado com sucesso!');
                }).catch(() => {
                    alert('Não foi possivel concluir a operação');
                }).finally(() => (this.loading = false));
                return;
            }

            if (!this.checkPermission('zapercontrol_notificacoes_adicionar')) {
                alert('Usuário sem permissão');
                return;
            }

            this.loading = true;
            this.$http.post('notificacoes', this.form).then(resp => {
                if (resp.data.type === 'warning') {
                    alert(resp.data.msg);
                    return;
                }
                this.$emit('onCadastro');
                // this.notify('Salvo com sucesso!');
                // this.reset();
            }).catch(() => {
                alert('Não foi possivel concluir a operação');
            }).finally(() => (this.loading = false));
        },

        openLink(url) {
            window.open(url, '_blank')
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
